/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    background: #39312f;
    font-size: 14px;
    padding: 0;
    color: rgba(255, 255, 255, 0.8);
    height: 40px;
  }
  #topbar .contact-info i {
    font-style: normal;
    color: #ff5821;
  }
  #topbar .contact-info i a, #topbar .contact-info i span {
    padding-left: 5px;
    color: #fff;
  }
  #topbar .contact-info i a {
    line-height: 0;
    transition: 0.3s;
  }
  #topbar .contact-info i a:hover {
    color: #ff5821;
  }
  #topbar .cta a {
    color: #fff;
    background: #ff5821;
    padding: 10px 20px;
    display: inline-block;
    transition: 0.3s;
  }
  #topbar .cta a:hover {
    background: #ff6b3b;
  }
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    height: 70px;
    transition: all 0.5s;
    z-index: 997;
    background: #fff;
  }
  #header.header-fixed {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    line-height: 0;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
  }
  #header .logo h1 a, #header .logo h1 a:hover {
    color: #635551;
    text-decoration: none;
  }
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
  }
  
  .scrolled-offset {
    margin-top: 70px;
  }
  
  #main {
    z-index: 3;
  }
  
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* new menu */
.navbar-toggler{
  border: 1px solid #f8f9fa;
}
.navbar-toggler:focus{
  outline: none;
  border: 1px solid #f8f9fa;
}

/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #635551;
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #ff5821;
}
.navbar .getstarted, .navbar .getstarted:focus {
  background: #ff5821;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}
.navbar .getstarted:hover, .navbar .getstarted:focus:hover {
  color: #fff;
  background: #ff6b3b;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}
 .navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
}
.navbar .dropdown ul a i {
  font-size: 12px;
} 
 .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #ff5821;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #473d3a;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
} 
.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(43, 37, 35, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
}
.navbar-mobile ul {
  display: block;
  /* position: absolute; */
  position:inherit;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  margin: 15px;
  /* margin-bottom: -50px; */
  border-radius: 10px;
  background-color: #fff;
  /* overflow-y: auto; */
  transition: 0.3s;
}
.navbar-mobile a, .navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #473d3a;
  text-decoration: none;
}
.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #ff5821;
  text-decoration: none;
}
.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
  text-decoration: none;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
  text-decoration: none;
}
.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #ff5821;
  text-decoration: none;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
  text-decoration: none;
}

.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  /* color: #ffffff; */
  white-space: nowrap;
  transition: 0.3s;
}