.formContent {
    grid-area: content;
    min-width: 1px;
}

*, ::after, ::before {
    box-sizing: border-box;
}
@media (min-width: 576px){
    .formBorder {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 1px;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;        
    }
}
.formBorder {
    position: relative;
    padding: 1rem;
    margin: 1rem -0.75rem 0;
    /* border: solid #dee2e6; */
    border-width: 1px 0 0;
    min-width: 350px;
    max-width: 700px;
    align-items: center;
    justify-content: center;
}

.orangeHeader {
    color: #fff;
    background-color: #ff5821;
}

.button-container {
    text-align: center; /* Center the buttons */
    min-width: 130px;
  }

  .button-container button {
    margin: 0 2px; /* Add margin between the buttons */
  }