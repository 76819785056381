.containerCheckInOutForm {
    /* position: relative; */
    margin: auto;
    /* display: flex;
    -webkit-box-pack: center; */
    justify-content: center;
    padding: 24px;
    background-color: rgba(243, 246, 249, 0.6);
    border: 1px solid rgb(229, 234, 242);
    border-radius: 4px;
    max-width: 90%;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    margin-bottom: 14px;
    margin-right: 14px;
}

.buttonContainer {
    align-items: center;
    justify-content: flex-end;
    max-width: 300px;
    margin: auto;
}
.firstCheckInOutContainer {
    padding: 15px ;
    padding-top: 25px;
    background: #fcfcfc;
    margin-bottom: 10px;
    border-top: #d7d7d7 1px solid;
    justify-content: center;

  }

.checkInOutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}