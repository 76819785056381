.firstFinancialContainer {
  padding: 15px ;
  padding-top: 25px;
  background: #fcfcfc;
  margin-bottom: 10px;
  border-top: #d7d7d7 1px solid;
  justify-content: center;
}
.financialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.financialFormContainer {
    /* position: relative; */
    margin: auto;
    /* display: flex;
    -webkit-box-pack: center; */
    justify-content: center;
    padding: 24px;
    background-color: rgba(243, 246, 249, 0.6);
    border: 1px solid rgb(229, 234, 242);
    border-radius: 4px;
    max-width: 90%;
}
.financialChartContainer {
  /* position: relative; */
  margin: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 24px;
  background-color: rgba(243, 246, 249, 0.6);
  border: 1px solid rgb(229, 234, 242);
  border-radius: 4px;
  max-width: 90%;
}
.noInvoices {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.buttonContainer {
  align-items: center;
  justify-content: flex-end;
  max-width: 300px;
  margin: auto;
}

.page {
  flex-direction: row;
  background-color: #E4E4E4;
}
.buttonDwnInvoice {
  background-color: white; 
  color: #ff5821; 
  border: 1px solid #ff5821;
  height: 40px;
  width: 40px;
}
.buttonDwnInvoice:hover {
  background-color: #ff5821;
  color: white;
}
