.login__container{
    width: 400px;
    height: 500px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login__container__loginWith{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.error {
    color: red;
    font-size: 11px;
  }
  .login__container__title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .login__container__loginWith__login{
    font-size: 12px;
    margin-bottom: 10px;
  }

.login__container > img{
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

.login__container > form > input{
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    margin-bottom: 10px;
}

.login__container > form > button{
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    margin-bottom: 10px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
}

.login__container > form > button:hover{
    background-color: #fff;
    color: #000;
}

.login__container > form > a{
    text-decoration: none;
    color: #000;
    font-size: 12px;
}

.login__container > form > a:hover{
    text-decoration: underline;
}

.login__container > form > p{
    font-size: 12px;
}

.login__container > form > p > a{
    text-decoration: none;
    color: #000;
    font-size: 12px;
}

.login__container > form > p > a:hover{
    text-decoration: underline;
}

.login__container > form > p > a > span{
    font-weight: bold;
}

.login__container > form > p > a > span:hover{
    text-decoration: underline;
}