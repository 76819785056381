.containerPayedOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.form-control-pay {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border: 1px solid #ff5821;
  box-shadow: 0px 2px 4px 0px #00000029;
  cursor: pointer;
  margin-bottom: 15px;
  color: #ffffff;
  background-color: #ff5821;
  font-weight: 500;
  background: linear-gradient(150deg, #ff5825, #fb9879);
}
/* .form-control-pay:focus {
  background-color: #ff5821; 
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
} */
/* .form-control-pay:hover {
  background-color: #ffffff; 
  color: #313030;
  font-weight: 500;
} */