.newDepForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.formBorder {
    position: relative;
    padding: 1rem;
    margin: 1rem -0.75rem 0;
    /* border: solid #dee2e6; */
    border-width: 1px 0 0;
    min-width: 350px;
    max-width: 700px;
    align-items: center;
    justify-content: center;
}