.icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 25px;
    color:#313031;
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ff5825;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    /* font-weight: 600; */
}

.textStyle {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
}
.row .col span {
    font-size: 16px;
    font-weight: 500;
    color: #ff5825;
    font-family: "Open Sans", sans-serif;
}
.departmentsButton {
    /* background-color: #ff5825; */
    color: #635551;
    font-weight: 500;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    height:40px;
    margin: 5px 0px 5px 0px;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #ff5825;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.departmentsButton:hover {
    background-color: #ff5825;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
}
.departmentsButton:focus {
    background-color: #ff5825;
    color: #635551;
    font-weight: 500;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
}
.departmentsButton:active {
    background-color: #ff5825;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
}
.departmentsButton:visited {
    background-color: #ff5825;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
}
.accordion-button {
    font-weight: 500;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    height:40px;
    margin: 5px 0px 5px 0px;
    vertical-align: middle;
    text-align: center;
    /* border: 1px solid #ff5825; */
    display: flex;
    align-items: center;
    justify-content: center;
}
/* events */
.eventsCardContainer{
    border: 1px solid #ff5825;
    margin-left: auto;
    margin-right: auto;
}
.eventsCardHeader {
    background-color:#ff5825; 
    color:#ffffff;
    font-weight: 500;
    font-size: 17px;
}

@media screen and (max-width: 768px) {
    .eventsCardContainer {
        border: 1px solid #ff5825;
        margin-left: auto;
        margin-right: auto;
    }

    .eventsCardHeader {
        font-size: 15px;
    }
}
/* Stiluri pentru ecrane între 769px și 1024px (tablete, de exemplu) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .eventsCardContainer {       
        padding: 15px;
    }

    .eventsCardHeader {
        font-size: 16px;
    }
}
/* slider */
.theme {
    padding: 14px;
    border-radius: 15px;
    border: 1px solid;
    margin: 0 0 0 15px;
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    background-position: center;
    width: 250px;
    height: 250px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  }

  .glider {
    overflow: hidden;
    position: relative;
  }
  
  .glider-contain {
    width: 500px;
    /* 370px; */

  }
  
  .glider-contain.hidden {
    opacity: 0;
  }
  
  .glider-next,
  .glider-prev {

  }

  .glider-track {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    z-index: 1;
    height: 250px;
    scrollbar-width: none;
}
@-moz-document url-prefix() {
    .glider-track {
      margin-bottom: 17px;
    }
    .glider-wrap {
      overflow: hidden;
    }
  }  
.uiContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: auto;
    /* border: 1px solid #000000; */
}
.uiContent {
    align-items: center; 
    justify-content: center;
    /* border: 1px solid #c71e1e; */
}

.uiTitle {
    text-align: center;
    padding-bottom: 5px;
  }
.uiTitle h1 {
    font-size: 32px;
    font-weight: 500;
    margin-top: 10px;
    padding-bottom: 0;
    font-family: "Poppins", sans-serif;
    color: #635551;
}
.headerCarousel {
    /* background-color:#ff5825;  */
    color:#ff5825;
    font-weight: 500;
    font-size: 17px;
    margin-top: 8px;
}

/*telefoane*/
@media only screen and (max-width: 600px) {
    .uiContainer {
        flex-direction: column; /* Schimbă direcția de afișare pe coloană pentru ecrane mici */
        margin: 10px;
    }    
    .uiContent {
        width: 100%; 
    }
    .uiTitle h1 {
        font-size: 28px;
    }
}
/* Media query pentru tablete */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .uiContainer {
        margin: 15px;
    }
    .uiContent {
        width: 90%;
    }
    .uiTitle h1 {
        font-size: 24px;
    }
}

.acordeonContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #000000;
}
.accordion-button:not(.collapsed) {
    /* color: var(--bs-accordion-active-color); */
    background-color: #ff5825;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    color: #ffffff;
}