.firstSuccessContainer {
  padding: 15px ;
  padding-top: 5px;
  background: #fcfcfc;
  margin-bottom: 10px;
  border-top: #d7d7d7 1px solid;
  justify-content: center;
  
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 100px;
}

.containerSuccessNetworksForm {
  /* position: relative; */
  margin: auto;
  /* display: flex;
  -webkit-box-pack: center; */
  justify-content: center;
  padding: 24px;
  background-color: rgba(243, 246, 249, 0.6);
  border: 1px solid rgb(229, 234, 242);
  border-radius: 4px;
  max-width: 90%;
  min-height: 600px;
  max-height: max-content;
}
.paymentButton {
  background-color: white; 
  color: #ff5821; 
  border: 1px solid #ff5821;
  height: 40px;
  width: 100px;
}
.paymentButton:hover {
  background-color: #ff5821;
  color: white;
}